<template>
    <div class="add-modify-user">
        <el-dialog 
            :title="comTitle"
            :visible.sync="show"
            :close-on-click-modal="false"
            width="424px">
            <el-form :model="userInfo" :rules="rules" ref="userInfo" label-width="68px" class="data-form" @submit.native.prevent>
                <el-form-item label="组织机构" prop="orgName" class="el-w292">
                    <popover @nodeClick="nodeClick" v-model="openOrClose" ref="tree" :data="newTreeData"  @hide="openOrClose = false" @show="openOrClose = true">
                        <div slot="treeReference" >
                            <div class="org-input">
                                <el-input placeholder="请选择组织机构" class="input-w290-h32" disabled :class="openOrClose ? 'pop-open-style' : 'pop-close-style'" v-model="userInfo.orgName"></el-input>
                                <svg-icon :class="{'svg-icon': true, 'icon-link': true, 'icon-transform': !openOrClose} " icon-class="icon-select" size="16"></svg-icon>
                            </div>
                        </div>
                    </popover>
                </el-form-item>
                <el-form-item label="用户名称" prop="realName">
                    <el-input class="input-w290-h32" v-model="userInfo.realName" placeholder="请输入用户名称"></el-input>
                </el-form-item>
                <el-form-item label="账号" prop="userName">
                    <el-input class="input-w290-h32" autocomplete="off" :disabled="this.title == 'add'?false:true" placeholder="请输入账号" v-model="userInfo.userName" ></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password" >
                    <el-input class="input-w290-h32" type="password" autocomplete="off" auto-complete="new-password" v-model="userInfo.password" placeholder="请输入密码"></el-input>
                </el-form-item>
                <el-form-item label="账号启用" prop="status">
                    <el-switch :width="64" v-model="userInfo.status"></el-switch>
                </el-form-item>
                <div class="role-list">
                    <el-form-item label="绑定角色" prop="roleIdList">
                        <div class="role-list-check">
                            <el-checkbox-group v-model="userInfo.roleIdList">
                                <el-checkbox v-for="(item, index) in roleList" :label="item.roleId" :key="index">
                                    <text-tooltip
                                        :content="item.roleName"
                                        class="wid190"
                                        refName="supplierName"
                                    ></text-tooltip>
                                </el-checkbox>
                            </el-checkbox-group>
                        </div>
                    </el-form-item>
                </div>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <tem-btn  type="info" @click="show = false"  value="取消"></tem-btn>
                <tem-btn  type="primary" @click="submitData('userInfo')"  value="确定"></tem-btn>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import popover  from '@/components/popover/index'
import textTooltip from '@/components/textTooltip/index'
export default {
    model: {
        prop: 'userDialogVisible',
        event: 'userEvent'
    },
    components:{
        popover,
        textTooltip
    },
    props:{
        userDialogVisible:{
            type: Boolean,
            default: true
        },
        title: {
            type: String,
            default:'add'
        },
        userInfo:{
            type: Object,
            default:() => {
                return {
                    orgId:'',
                    orgName:'',
                    realName:'',
                    userName:'',
                    password:'',
                    status:true,
                    roleIdList:[],
                }
            }
        },
        roleList:{
            type: Array,
            default:() => {
                return []
            }
        },
        treeData:{
            type: Array,
            default:() => {
                return []
            }
        },
    },
    computed:{
        comTitle(){
            return this.title == 'add' ? '新增用户' : '编辑用户'
        }
    },
    data(){
        return {
            show: this.userDialogVisible,
            rules:{
                // orgId:[{ required: true, message: '请选择组织机构', trigger: 'change' }],
                orgName:[{ required: true, message: '请选择组织机构', trigger: 'change' }],
                realName:[{ required: true, message: '请输入用户名称', trigger: 'change' },
                        { required: true, pattern: /^[A-Za-z0-9\u4e00-\u9fa5]{2,20}$/, message: '中文或数字或字母，长度 2-20 个字符', trigger: 'change' }],
                userName:[{required: true, message: '字母或数字,长度5-20个字符',pattern:/^[a-z0-9A-Z]{5,20}$/, trigger: 'change' }],
                password: [{ message: '包含数字,字母或字符至少两种,长度8-16个字符',pattern:/(?!.*\s)(?!^[\u4e00-\u9fa5]+$)(?!^[0-9]+$)(?!^[A-z]+$)(?!^[^A-z0-9]+$)^.{6,16}$/, trigger: 'change' }],
                roleIdList:[ { required: true, message: '请选择权限'} ]
            },
            newTreeData:this.treeData,
            openOrClose: false,
        }
    },
    watch: {
        //对父组件传来的地址进行监听
        show:{
            handler(newVal, oldVal) {
                this.$emit('userEvent',newVal);
                if(!newVal && this.$refs.userInfo) {
                     setTimeout(()=>{
                        this.$refs.userInfo.resetFields();
                    },300)
                    //关闭弹框的时候  关闭popover组织机构
                    this.openOrClose = false;
                }
            },
            deep: true,
        },
        treeData:{
            handler(newVal, oldVal) {
                this.newTreeData = newVal;
            },
            deep: true,
        },
        userDialogVisible:{
            handler(newVal, oldVal) {
                this.show = newVal;
            },
            deep: true,
        },
    },
    methods:{
        submitData(formName){
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.$emit('submitData',this.userInfo);
                }
            });
        },
        nodeClick(obj,flag){
            this.$set( this.userInfo,'orgName',obj.orgName);
            this.$set( this.userInfo,'orgId',obj.orgId);
            //双击关闭popover
            if(flag == 'db') {
                this.openOrClose = false;
            }
        },
    }
}
</script>
<style lang="scss">
.add-modify-user { 
    .role-list {
        .role-list-check {
            max-height: 144px;
            overflow: auto;
            padding: 8px 26px;
        }
        .el-form-item__content {
            margin-left: 0 !important;
            margin-top: 46px;
            border-radius: 4px;
            border: 1px solid #D9D9D9;
            box-sizing: border-box;
        }
        .el-checkbox {
            margin-right: 13px !important;
            width: 92px;
        }
        .el-checkbox__input {
            vertical-align: middle;
        }
        .el-checkbox__label {
            padding-left: 8px;
            vertical-align: middle; 
            .wid190 {
                width: 70px;
            }
            span {
                font-size: 14px;
                color: #595959;
            }
        }
    }
    
}
</style>