<template>
  <div>
    <div class="user-management">
        <div class="search-nav">
            <div class="search-form">
                <el-form :inline="true" ref="listQuery" :model="listQuery" @submit.native.prevent>
                    <div class="form jcl">
                        <div class="search-conditions">   
                            <el-form-item label="用户名称" prop="realName">
                                <el-input class="input-w290-h32" :maxlength="120" v-model="listQuery.realName" placeholder="请输入用户名称"></el-input>
                            </el-form-item>
                        </div>
                        <div class="search-btns">
                            <el-form-item class="clear-margin-bottom clear-margin-right" label="" prop="includeSub">
                                <!-- <el-checkbox v-model="listQuery.includeSub">包含下级</el-checkbox> -->
                            </el-form-item>
                            <tem-btn  type="primary" value="查询" @click="getTableList('search')"></tem-btn>
                            <tem-btn  type="info" value="清空" @click="restForm('listQuery')"></tem-btn>
                        </div>
                    </div>
                </el-form>
            </div>
        </div>
        <div class="table-list">
            <Table :table="table" :total="listQuery.total" :page.sync="listQuery.pageIndex" :size.sync="listQuery.pageSize" :sortIndex.sync="listQuery.sort"
            :orderBy.sync="listQuery.orderField" @pagination="getTableList"></Table>
        </div>
      </el-row>
    </div>
    <div class="copyright">Copyright©2018-{{(new Date()).getFullYear()}} 重庆几米信息技术有限公司 版权所有</div>
    <add-or-modify @submitData="submitData" :treeData="treeData" :roleList="roleList" :title="title" v-model="userDialogVisible" :userInfo="userInfo"></add-or-modify>
  </div>
</template>

<script>
  import Table from '@/components/table/table';
  import addOrModify from './addOrModifyUser';
  import { listTreeOrg } from '@/api/organization-management/index';
  import {
    listPageUser,
    listAllRole,
    addUserAndRole,
    modifyUserAndRole,
    modifyUser,
    getUserAndRole,
  } from '@/api/user-management/index';
  export default {
    components: {
      Table,
      addOrModify
    },
    data() {
      return {
          //用户信息对象
        userInfo:{
            orgId:'',
            orgName:'',
            realName:'',
            userName:'',
            password:'',
            status:true,
            roleIdList:[]
        },
        //所有角色
        roleList: [],
        //搜索栏数据
        listQuery:{
            userName:'',//机构名称
            total:10000,//数据条数
            includeSub:false,//是否包含下级
            pageIndex: 1,//当前查询页面
            pageSize: 10,//每页数据数量
            orderField:null,
            sort:''
        },
        //表格数据
        table: {
          configItem: true, //是否开启表格项配置
          data: [],
          //表头按钮
          operateBtns:[
              {
                  value:'新增用户',
                  type:'primary',
                  event:() => {
                      this.listTreeOrg().then( ()=> {
                        //获取所有角色
                        this.title = 'add';
                        this.getListAllRole().then( () => {
                            this.userDialogVisible = true;
                        });
                      })
                  }
              }
          ],
          header: [
            {
              type: 'index',
              label: '序号',
              width: '80',
              ifShow: true,
            },
            {
              prop: 'realName',
              label: '用户名称',
              minWidth: '130',
              ifShow: true,
            },
            {
              prop: 'orgName',
              label: '组织机构',
              minWidth: '150',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'userName',
              label: '账号',
              minWidth: '130',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'createrName',
              label: '创建人',
              minWidth: '130',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'createTime',
              label: '创建时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'updaterName',
              label: '更新人',
              minWidth: '120',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'updateTime',
              label: '更新时间',
              sortable: 'column',
              minWidth: '180',
              tooltip: true,
              ifShow: true,
            },
            {
              prop: 'status',
              label: '状态',
              minWidth: '100',
              tooltip: true,
              ifShow: true,
              render:(h,param) => {
                  return h('div', [
                    h(
                        'el-switch', {
                            props: {
                                value: param.status == 1 ? true : false 
                            },
                            on: {
                                change:(val)=> {
                                    param.status == 1 ? param.status = true : param.status = false;
                                    modifyUser({status: !param.status ? 1 : 2,userId:param.userId}).then( res => {
                                        if( res.code === 200 ) {
                                            param.status = !param.status;
                                            this.getTableList();
                                            if(param.status) {
                                                this.$message({type:'success',message:'启用成功'});
                                            } else {
                                                this.$message({type:'success',message:'停用成功'});
                                            }
                                        }
                                    })
                                }
                            }
                        }
                    ),
                ]);
              }
            },
            // {
            //   prop: 'desc',
            //   label: '备注',
            //   minWidth: '180',
            //   tooltip: true,
            //   ifShow: true,
            // },
            {
              prop: 'options',
              label: '操作',
              minWidth: '100',
              ifShow: true,
              fixed:'right',
              render:(h,param) => {
                return h('div', [
                    h(
                        'el-tooltip', {
                            props: {
                                effect:'dark',
                                content:'编辑',
                                placement:'top'
                            }
                        },
                        [h('span',{
                            style:{
                                cursor:'pointer',
                            },
                            class:'svg-hover',
                            on:{
                                click:()=> {
                                    this.title = 'edit'
                                    this.listTreeOrg().then( () => {
                                        this.getListAllRole().then( () => {
                                            this.getUserAndRole(param.userId);
                                        });
                                    })
                                }
                            }
                        },[
                            h('svg-icon',{
                                props:{
                                    iconClass:'icon-edit',
                                    size: '16',
                                    className:' icon'
                                },
                            })
                        ])
                        ]
                    ),
                ]);
              }
            }
          ],
        },
        userDialogVisible: false,//新增用户弹框
        title: 'add',
        treeData:[]//组织树数据
      }
    },
    mounted() {
        //获取列表数据
        this.getTableList();
    },
    methods: {
        /**
         * 获取机构数据
         * @param {Number} orgId 机构id
         */
        listTreeOrg(orgId){
            return new Promise( ( resolve , reject ) => {
                listTreeOrg({currentOrgId:orgId,excludeChild:true}).then( res => {
                    if( res.code == 200 ) {
                        this.treeData = res.data;
                        resolve('success')
                    }
                })
            }) 
        },
        /**
         * 获取用户信息
         * @param {Number} userId 用户id
         */
        getUserAndRole(userId){
            getUserAndRole({userId,userId}).then( res => {
                if( res.code === 200 ) {
                    this.userDialogVisible = true;
                    this.$nextTick( () => {
                        this.userInfo = res.data;
                        this.userInfo.status = res.data.status == 1 ? true: false;
                    })
                    
                }
            })
        },
        /**
         * 表单提交数据
         * @param {Object } userInfo 用户信息对象 
         */
        submitData(userInfo){
            let newUserInfo = JSON.parse(JSON.stringify(userInfo));
            //密码加密
            newUserInfo.password =this.RSA.getRsaCode(newUserInfo.password);
            newUserInfo.status = newUserInfo.status ? 1 : 2;
            if(this.title == 'add') {
                addUserAndRole(newUserInfo).then( res => {
                    if( res.code === 200 ) {
                        this.userDialogVisible = false;
                        this.getTableList();
                        this.$message({type:'success',message:'新增用户成功'})
                    }
                })
            } else if( this.title == 'edit') {
                modifyUserAndRole(newUserInfo).then( res => {
                    if( res.code === 200 ) {
                        this.userDialogVisible = false;
                        this.getTableList();
                        this.$message({type:'success',message:'编辑用户成功'})
                    }
                })
            }
        },
        /**
         * 获取所有角色列表
         */
        getListAllRole(){
            return new Promise( (resolve,reject) => {
                listAllRole().then( res => {
                    if( res.code == 200 ) {
                        this.roleList = res.data;
                        resolve('success')
                    }
                }) 
            })
        },
        /**
         * 重置搜索导航栏
         * @param { Object } formName 当前表单的ref值
         */
        restForm(formName){
            this.$refs[formName].resetFields();
        },
        /**
         * 获取表格数据
         */
        getTableList(flag){
            if(flag == 'search') {
                this.listQuery.pageIndex = 1;
            }
            listPageUser(this.listQuery).then( res => {
                if( res.code === 200 ) {
                    this.table.data = res.data;
                    this.listQuery.total = res.count;
                }
            })
        },
    }
  }

</script>
<style lang="scss" scoped>
.user-management {
}

</style>